import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GestureResponderEvent } from 'react-native';

import { FormattedMessage } from 'react-intl';

import { FlashList } from '@shopify/flash-list';
import _without from 'lodash/without';
import { useDebounce } from 'usehooks-ts';

import { Auth0UserPermissions, useArrivedAuth0 } from '@arrived/arrived-auth0';
import {
  Button,
  Divider,
  Filter,
  ScrollView,
  Select,
  Sheet,
  SheetProps,
  Stack,
  UtilityText,
  getTokens,
  getVariableValue,
  isWeb,
  styled,
  useSheetImperativeContext,
  withStaticProperties,
} from '@arrived/bricks';
import { OfferingFiltersContextProvider, useOfferingFilters } from '@arrived/contexts';
import { FEATURE_FLAGS, useIsFeatureFlagEnabled } from '@arrived/feature-flags';
import { usePermissions } from '@arrived/hooks';
import {
  AvailabilityFilter,
  InvestmentCategory,
  LeverageFilter,
  OwnershipFilter,
  RentStatusFilter,
  convertOfferingFilterToSearchQuery,
} from '@arrived/models';
import {
  useGetCurrentUserQuery,
  useGetMarketsQuery,
  useOfferingSearchQuery,
  useRegulationAccessSummaryQuery,
} from '@arrived/queries';

import { useInvestContext } from '../InvestContext';

const sizeTokens = getTokens().size;

export interface AssetTypeFiltersProps {
  onFilterChange?: (e: GestureResponderEvent) => void;
}

export const AssetTypeFilters = ({ onFilterChange }: AssetTypeFiltersProps) => {
  const scrollRef = useRef<FlashList<(typeof filterList)[number]>>(null);

  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);

  const { hasPermission } = usePermissions();
  const currentUserState = useGetCurrentUserQuery();

  const { isFilterActive, offeringFilter, setOfferingFilter } = useOfferingFilters();
  const regDAccessState = useRegulationAccessSummaryQuery();

  const isRealEstateDebtFilterEnabled = useIsFeatureFlagEnabled(FEATURE_FLAGS.REAL_ESTATE_DEBT_FILTER);

  const isAdmin = useMemo(
    () => currentUserState.data?.admin || hasPermission(Auth0UserPermissions.ALL),
    [currentUserState.data?.admin, hasPermission],
  );

  const filterList = useMemo(
    () =>
      [
        {
          name: <FormattedMessage id="common.all" />,
        },
        {
          investmentCategory: InvestmentCategory.SINGLE_FAMILY,
          name: <FormattedMessage id="common.single.family.residential" />,
        },
        {
          investmentCategory: InvestmentCategory.VACATION,
          name: <FormattedMessage id="common.vacation.rental" />,
        },
        {
          investmentCategory: InvestmentCategory.FUNDS,
          name: <FormattedMessage id="common.funds" />,
        },
        !isRealEstateDebtFilterEnabled &&
          (isAdmin || regDAccessState.data?.canViewRegDOffering) && {
            investmentCategory: InvestmentCategory.SHORT_TERM_NOTE,
            name: <FormattedMessage id="common.short-term-note" />,
          },
        isRealEstateDebtFilterEnabled && {
          investmentCategory: InvestmentCategory.REAL_ESTATE_DEBT,
          name: <FormattedMessage id="invest.real-estate-debt" />,
        },
      ].filter((x): x is Exclude<typeof x, false | null | undefined | '' | 0> => Boolean(x)),
    [isAdmin, isRealEstateDebtFilterEnabled, regDAccessState.data?.canViewRegDOffering],
  );

  const handleFilterPress = useCallback(
    (e: GestureResponderEvent, index: number, investmentCategory?: InvestmentCategory) => {
      onFilterChange?.(e);

      setCurrentCategoryIndex(index);

      const newOfferingFilter = { ...offeringFilter };

      // If we're setting investmentCategory to null OR if we're pressing the currently selected
      // investment category then we should treat that as a removal of that selection.
      if (investmentCategory == null || investmentCategory === newOfferingFilter.investmentCategory) {
        delete newOfferingFilter.investmentCategory;
      } else {
        newOfferingFilter.investmentCategory = investmentCategory;

        switch (investmentCategory) {
          case InvestmentCategory.FUNDS:
          case InvestmentCategory.REAL_ESTATE_DEBT:
          case InvestmentCategory.SHORT_TERM_NOTE:
            delete newOfferingFilter.leverage;
            delete newOfferingFilter.markets;
            delete newOfferingFilter.rentStatus;
            break;
          case InvestmentCategory.VACATION:
            delete newOfferingFilter.rentStatus;
            break;
        }
      }

      setOfferingFilter(newOfferingFilter);
    },
    [offeringFilter, onFilterChange, setCurrentCategoryIndex, setOfferingFilter],
  );

  useEffect(() => {
    // This is really native only, so it will no-op on web
    scrollRef.current?.scrollToIndex({ index: currentCategoryIndex, viewOffset: 0, animated: true });
  }, [currentCategoryIndex]);

  return isWeb ? (
    <>
      {/* This allows us to hide the scrollbar on web when server rendered */}
      <style jsx global>{`
        .server-category-list {
          scrollbar-width: none;
        }
      `}</style>
      <ScrollView
        className="server-category-list"
        horizontal
        flexShrink={1}
        contentContainerStyle={{
          gap: getVariableValue(sizeTokens.$2),
        }}
      >
        {filterList.map((item, index) => (
          <Filter
            key={`category-filter-${item.investmentCategory}-${index}`}
            active={isFilterActive('investmentCategory', item.investmentCategory)}
            labelProps={{ whiteSpace: 'nowrap' }}
            onPress={(e) => handleFilterPress(e, index, item.investmentCategory)}
          >
            {item.name}
          </Filter>
        ))}
      </ScrollView>
    </>
  ) : (
    <FlashList
      ref={scrollRef}
      data={filterList}
      horizontal
      showsHorizontalScrollIndicator={false}
      keyExtractor={(item) => `${item.investmentCategory ?? 'all'}`}
      ItemSeparatorComponent={() => <Stack width="$2" />}
      estimatedItemSize={100}
      extraData={{
        isRealEstateDebtFilterEnabled,
        canViewRegDOffering: regDAccessState.data?.canViewRegDOffering,
        currentCategoryIndex,
        isAdmin,
      }}
      renderItem={({ item, index }) => (
        <Filter
          active={isFilterActive('investmentCategory', item.investmentCategory)}
          labelProps={{ whiteSpace: 'nowrap' }}
          onPress={(e) => handleFilterPress(e, index, item.investmentCategory)}
        >
          {item.name}
        </Filter>
      )}
    />
  );
};

const OwnershipFilters = () => {
  const { isFilterActive, updateOfferingFilter } = useOfferingFilters();

  return (
    <>
      <Filter active={isFilterActive('ownership')} onPress={() => updateOfferingFilter('ownership')}>
        <FormattedMessage id="common.all" />
      </Filter>
      <Filter
        active={isFilterActive('ownership', OwnershipFilter.OWNED)}
        onPress={() => updateOfferingFilter('ownership', OwnershipFilter.OWNED)}
      >
        <FormattedMessage id="common.owned" />
      </Filter>
      <Filter
        active={isFilterActive('ownership', OwnershipFilter.NOT_OWNED)}
        onPress={() => updateOfferingFilter('ownership', OwnershipFilter.NOT_OWNED)}
      >
        <FormattedMessage id="common.not-owned" />
      </Filter>
    </>
  );
};

const AvailabilityFilters = () => {
  const { isFilterActive, updateOfferingFilter } = useOfferingFilters();

  return (
    <>
      <Filter active={isFilterActive('availability')} onPress={() => updateOfferingFilter('availability')}>
        <FormattedMessage id="common.all" />
      </Filter>
      <Filter
        active={isFilterActive('availability', AvailabilityFilter.FOR_SALE)}
        onPress={() => updateOfferingFilter('availability', AvailabilityFilter.FOR_SALE)}
      >
        <FormattedMessage id="common.for.sale" />
      </Filter>
      <Filter
        active={isFilterActive('availability', AvailabilityFilter.SOLD_OUT)}
        onPress={() => updateOfferingFilter('availability', AvailabilityFilter.SOLD_OUT)}
      >
        <FormattedMessage id="common.fully.funded" />
      </Filter>
      <Filter
        active={isFilterActive('availability', AvailabilityFilter.COMING_SOON)}
        onPress={() => updateOfferingFilter('availability', AvailabilityFilter.COMING_SOON)}
      >
        <FormattedMessage id="common.coming-soon" />
      </Filter>
    </>
  );
};

const RentStatusFilters = () => {
  const {
    isFilterActive,
    offeringFilter: { investmentCategory },
    updateOfferingFilter,
  } = useOfferingFilters();

  const isDisabled = useMemo(
    () => investmentCategory && investmentCategory !== InvestmentCategory.SINGLE_FAMILY,
    [investmentCategory],
  );

  return (
    <>
      <Filter
        disabled={isDisabled}
        active={isFilterActive('rentStatus')}
        onPress={() => updateOfferingFilter('rentStatus')}
      >
        <FormattedMessage id="common.all" />
      </Filter>
      <Filter
        disabled={isDisabled}
        active={isFilterActive('rentStatus', RentStatusFilter.RENTED)}
        onPress={() => updateOfferingFilter('rentStatus', RentStatusFilter.RENTED)}
      >
        <FormattedMessage id="common.rented" />
      </Filter>
      <Filter
        disabled={isDisabled}
        active={isFilterActive('rentStatus', RentStatusFilter.SEEKING_TENANT)}
        onPress={() => updateOfferingFilter('rentStatus', RentStatusFilter.SEEKING_TENANT)}
      >
        <FormattedMessage id="common.seeking-tenant" />
      </Filter>
    </>
  );
};

const LeverageFilters = () => {
  const {
    isFilterActive,
    offeringFilter: { investmentCategory },
    updateOfferingFilter,
  } = useOfferingFilters();

  const isDisabled = useMemo(
    () =>
      investmentCategory &&
      (
        [
          InvestmentCategory.FUNDS,
          InvestmentCategory.SHORT_TERM_NOTE,
          InvestmentCategory.REAL_ESTATE_DEBT,
        ] as InvestmentCategory[]
      ).includes(investmentCategory),
    [investmentCategory],
  );

  return (
    <>
      <Filter
        disabled={isDisabled}
        active={isFilterActive('leverage')}
        onPress={() => updateOfferingFilter('leverage')}
      >
        <FormattedMessage id="common.all" />
      </Filter>
      <Filter
        disabled={isDisabled}
        active={isFilterActive('leverage', LeverageFilter.NONE)}
        onPress={() => updateOfferingFilter('leverage', LeverageFilter.NONE)}
      >
        <FormattedMessage id="common.none" />
      </Filter>
      <Filter
        disabled={isDisabled}
        active={isFilterActive('leverage', LeverageFilter.LEVERAGED)}
        onPress={() => updateOfferingFilter('leverage', LeverageFilter.LEVERAGED)}
      >
        <FormattedMessage id="common.leveraged" />
      </Filter>
    </>
  );
};

const MarketFilter = () => {
  const { offeringFilter, setOfferingFilter } = useOfferingFilters();

  const { data: marketsList = [], isSuccess } = useGetMarketsQuery({
    select: (data) => data.sort((market1, market2) => market1.title.localeCompare(market2.title)) ?? [],
  });

  const { investmentCategory, markets } = offeringFilter;

  const isDisabled = useMemo(
    () =>
      investmentCategory &&
      (
        [
          InvestmentCategory.FUNDS,
          InvestmentCategory.SHORT_TERM_NOTE,
          InvestmentCategory.REAL_ESTATE_DEBT,
        ] as InvestmentCategory[]
      ).includes(investmentCategory),
    [investmentCategory],
  );

  return (
    <Select
      aria-labelledby="location-label"
      disabled={isDisabled || !isSuccess || marketsList.length === 0}
      multiple
      width="100%"
      placeholder={
        isSuccess ? (
          <FormattedMessage id="common.all-locations-count" values={{ count: marketsList.length }} />
        ) : (
          <FormattedMessage id="common.all-locations" />
        )
      }
      value={markets ?? []}
      onChange={(value) => setOfferingFilter({ ...offeringFilter, markets: value })}
    >
      {marketsList.map(({ id, title }) => (
        <Select.Item key={id} value={id} label={title} />
      ))}
    </Select>
  );
  return null;
};

const FilterSection = withStaticProperties(
  styled(Stack, {
    gap: '$3',
  }),
  {
    Header: styled(UtilityText, { token: 'utility.label.xsmall' }),
    Content: styled(Stack, { flexDirection: 'row', flexWrap: 'wrap', columnGap: '$1', rowGap: '$2' }),
  },
);

export const FilterSheet = ({ onClose: onCloseProp, ...rest }: Omit<SheetProps, 'children' | 'open'>) => {
  const {
    useDisclosureProps: { isOpen, onClose },
  } = useInvestContext();

  const { offeringFilter, setOfferingFilter } = useOfferingFilters();

  const [internalOfferingFilter, setInternalOfferingFilter] = useState(offeringFilter);

  const handleOnClose = useCallback(() => {
    onClose();
    onCloseProp?.();
  }, [onClose, onCloseProp]);

  const handleOnConfirm = useCallback(
    () => setOfferingFilter(internalOfferingFilter),
    [internalOfferingFilter, setOfferingFilter],
  );

  useEffect(() => {
    if (isOpen) {
      setInternalOfferingFilter(offeringFilter);
    }
  }, [isOpen]);

  return (
    <Sheet variant="form" onClose={handleOnClose} open={isOpen} {...rest}>
      <OfferingFiltersContextProvider
        offeringFilter={internalOfferingFilter}
        setOfferingFilter={setInternalOfferingFilter}
      >
        <FilterSheetContent onConfirm={handleOnConfirm} />
      </OfferingFiltersContextProvider>
    </Sheet>
  );
};

interface FilterSheetContentProps {
  onConfirm: () => void;
}

const FilterSheetContent = ({ onConfirm }: FilterSheetContentProps) => {
  const { isAuthenticated } = useArrivedAuth0();

  const { offeringFilter, setOfferingFilter } = useOfferingFilters();
  const { onClose } = useSheetImperativeContext();

  const offeringSearchQuery = useMemo(
    () => ({ ...convertOfferingFilterToSearchQuery(offeringFilter), size: 1, page: 1, fields: ['id'] }),
    [offeringFilter],
  );

  const offeringState = useOfferingSearchQuery(offeringSearchQuery);

  const showLoading = useDebounce(offeringState.isFetching, 200);

  return (
    <>
      <Sheet.Header>
        <Sheet.Header.Title>
          <Sheet.Header.Title.Text>
            <FormattedMessage id="common.filters" />
          </Sheet.Header.Title.Text>
          <Sheet.Header.CloseIcon />
        </Sheet.Header.Title>
      </Sheet.Header>
      <Sheet.Divider />
      <Sheet.Body data-e2e="invest-filter-sheet-body">
        <Sheet.ScrollView>
          <Stack gap="$6" py="$6">
            <FilterSection>
              <FilterSection.Header>
                <FormattedMessage id="common.type" />
              </FilterSection.Header>
              <FilterSection.Content>
                <AssetTypeFilters />
              </FilterSection.Content>
            </FilterSection>
            <Divider />
            <FilterSection display={isAuthenticated ? undefined : 'none'}>
              <FilterSection.Header>
                <FormattedMessage id="common.ownership" />
              </FilterSection.Header>
              <FilterSection.Content>
                <OwnershipFilters />
              </FilterSection.Content>
            </FilterSection>
            <FilterSection>
              <FilterSection.Header>
                <FormattedMessage id="common.availability" />
              </FilterSection.Header>
              <FilterSection.Content>
                <AvailabilityFilters />
              </FilterSection.Content>
            </FilterSection>
            <FilterSection>
              <FilterSection.Header id="location-label">
                <FormattedMessage id="common.location" />
              </FilterSection.Header>
              <FilterSection.Content>
                <MarketFilter />
              </FilterSection.Content>
            </FilterSection>
            <FilterSection>
              <FilterSection.Header>
                <FormattedMessage id="common.rent-status" />
              </FilterSection.Header>
              <FilterSection.Content>
                <RentStatusFilters />
              </FilterSection.Content>
            </FilterSection>
            <FilterSection>
              <FilterSection.Header>
                <FormattedMessage id="common.current-leverage" />
              </FilterSection.Header>
              <FilterSection.Content>
                <LeverageFilters />
              </FilterSection.Content>
            </FilterSection>
          </Stack>
        </Sheet.ScrollView>
        <Sheet.Footer>
          <Sheet.Divider />

          <Button variant="ghost" onPress={() => setOfferingFilter({})}>
            <FormattedMessage id="common.filters.clear" />
          </Button>
          <Button
            data-e2e="invest-filter-sheet-show"
            onPress={() => {
              onConfirm();
              onClose?.();
            }}
            loading={showLoading}
          >
            {!offeringState.isSuccess ? (
              <FormattedMessage id="common.show-results" />
            ) : (
              <FormattedMessage
                id="common.show-x-results"
                values={{ count: offeringState.data?.pagination?.totalResults ?? 0 }}
              />
            )}
          </Button>
        </Sheet.Footer>
      </Sheet.Body>
    </>
  );
};
