import { ComponentProps, useMemo } from 'react';

import { FormattedMessage } from 'react-intl';
import Animated, { FadeInUp, FadeOut } from 'react-native-reanimated';

import { Callout as BricksCallout, CalloutProps } from '@arrived/bricks';
import { useOfferingFilters } from '@arrived/contexts';
import { FEATURE_FLAGS, useIsFeatureFlagEnabled } from '@arrived/feature-flags';
import { InvestmentCategory } from '@arrived/models';

import { useDisplayEmptyState, useInvestContext, useInvestContextOfferingSearchQuery } from '../InvestContext';
import { useShowCallout } from './useShowCallout';

interface CalloutContent {
  heading: ComponentProps<typeof FormattedMessage>['id'];
  content: ComponentProps<typeof FormattedMessage>['id'];
}

const AnimatedCallout = Animated.createAnimatedComponent(BricksCallout);

const useCalloutContent = (investmentCategory?: InvestmentCategory): CalloutContent | undefined => {
  const isRealEstateDebtFilterEnabled = useIsFeatureFlagEnabled(FEATURE_FLAGS.REAL_ESTATE_DEBT_FILTER);

  return useMemo(() => {
    switch (investmentCategory) {
      case InvestmentCategory.SINGLE_FAMILY:
        return {
          heading: 'filters.what-is-a.sfr',
          content: 'filters.what-is-a.sfr-description',
        };
      case InvestmentCategory.VACATION:
        return {
          heading: 'filters.what-is-a.str',
          content: 'filters.what-is-a.str-description',
        };
      case InvestmentCategory.FUNDS:
        return {
          heading: 'filters.what-is-a.fund',
          content: isRealEstateDebtFilterEnabled
            ? 'filters.what-is-a.fund-description-new'
            : 'filters.what-is-a.fund-description-old',
        };
      case InvestmentCategory.REAL_ESTATE_DEBT:
        return {
          heading: 'filters.what-is-a.real-estate-debt',
          content: 'filters.what-is-a.real-estate-debt-description',
        };
    }
  }, [investmentCategory, isRealEstateDebtFilterEnabled]);
};

export const Callout = (props: Omit<CalloutProps, 'hideCloseIcon' | 'variant'>) => {
  const { offeringFilter } = useOfferingFilters();
  const { paginationProps } = useInvestContext();

  const offeringState = useInvestContextOfferingSearchQuery({ offeringFilter, paginationProps });

  const displayEmptyState = useDisplayEmptyState(offeringState);

  const calloutContent = useCalloutContent(offeringFilter.investmentCategory);

  const [showCallout, setShowCallout] = useShowCallout(offeringFilter.investmentCategory);

  return (
    <>
      {!displayEmptyState && calloutContent && showCallout && (
        <AnimatedCallout
          key={offeringFilter.investmentCategory}
          entering={FadeInUp}
          exiting={FadeOut}
          onClose={() => setShowCallout(false)}
          variant="inverted"
          {...props}
        >
          <BricksCallout.Heading>
            <FormattedMessage id={calloutContent.heading} />
          </BricksCallout.Heading>
          <BricksCallout.Content>
            <BricksCallout.Content.Text>
              <FormattedMessage id={calloutContent.content} />
            </BricksCallout.Content.Text>
          </BricksCallout.Content>
        </AnimatedCallout>
      )}
    </>
  );
};
