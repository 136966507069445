import { Fragment, useCallback, useMemo, useState } from 'react';
import { Image, LayoutChangeEvent } from 'react-native';

import { FormattedMessage, FormattedNumber } from 'react-intl';

import { format, formatDistanceToNowStrict, parseISO } from 'date-fns';
import { useDebounce } from 'usehooks-ts';

import { OpenSearchOfferingDocument } from '@arrived/api_v2';
import {
  CheckmarkCircleIcon,
  CoinsIcon,
  Divider,
  KeyIcon,
  PadlockIcon,
  ProductCard,
  ProductCardProps,
  Ratio,
  Stack,
  StripeSvg,
  Tag,
  Tooltip,
  UtilityText,
  useIsomorphicLayoutEffect,
} from '@arrived/bricks';
import { Constants, OfferingDisplayState, OfferingTransactState, getTimezoneAgnosticDate } from '@arrived/common';
import {
  useGetProductTypeName,
  useInvestmentProductType,
  useOfferingDisplayState,
  useOfferingTransactState,
} from '@arrived/hooks';
import { cdnToImageUrl, ikClient } from '@arrived/imagekit';
import { InvestmentProductType, SecurityType } from '@arrived/models';
import { useGetAccountOfferingBalancesQuery, useGetPrimaryAccountQuery, useIsOfferingOwned } from '@arrived/queries';

import { InvestCardProgress } from './InvestCardProgress';
import { useInvestCardContent } from './useInvestCardContent';
import { useInvestCardTagContent } from './useInvestCardTagContent';

export interface InvestCardProps extends ProductCardProps {
  offering: OpenSearchOfferingDocument;
}

const ClosedProgressBar = () => (
  <Ratio h={8} width="100%">
    <Ratio.Inner h="100%" m={0}>
      <Ratio.Fill bg="$onSurface.neutral.outline" width="100%" h="100%">
        <StripeSvg color="$onSurface.neutral.muted" />
      </Ratio.Fill>
    </Ratio.Inner>
  </Ratio>
);

export const InvestCard = ({ offering, ...rest }: InvestCardProps) => {
  const [height, setHeight] = useState<number>();
  const debounced = useDebounce(height, 500);
  const offeringTransactState = useOfferingTransactState(offering);
  const investmentProductType = useInvestmentProductType(offering);
  const accountState = useGetPrimaryAccountQuery();
  const accountOfferingBalancesState = useGetAccountOfferingBalancesQuery(accountState.data?.id);

  const numSharesOwned = useMemo(
    () => accountOfferingBalancesState.data?.offerings.find(({ id }) => id === offering.id)?.totalSharesCount ?? 0,
    [accountOfferingBalancesState.data, offering.id],
  );

  const getImageSrc = useCallback(
    ({ height, blur }: { height: number; blur?: number }) => {
      const transformation = [{ height: `${height * 1.5}` }] as Array<Record<string, string>>;
      if (blur) {
        transformation.push({ blur: `${blur}` });
      }
      return ikClient.url({
        path: offering.thumbnailPhotoUrl
          ? cdnToImageUrl(offering.thumbnailPhotoUrl)
          : Constants.defaultPropertyImagePath,
        transformation,
      });
    },
    [offering.thumbnailPhotoUrl],
  );

  const [imageSrc, setImageSrc] = useState(getImageSrc({ height: 100, blur: 10 }));

  useIsomorphicLayoutEffect(() => {
    if (debounced == null) {
      return;
    }
    const newImageSrc = getImageSrc({ height: debounced });
    let cancelled = false;
    Image.prefetch(newImageSrc).then(() => {
      if (!cancelled) {
        setImageSrc(newImageSrc);
      }
    });
    return () => {
      cancelled = true;
    };
  }, [debounced, getImageSrc]);

  const isOfferingOwned = useIsOfferingOwned()(offering.id);
  const offeringDisplayState = useOfferingDisplayState(offering.id);
  const hasLimitedOfferingTag = useMemo(
    () => offering.maxSharesPerAccount && offeringDisplayState !== OfferingDisplayState.SOLD_OUT,
    [offering.maxSharesPerAccount, offeringDisplayState],
  );
  const investCardTagContent = useInvestCardTagContent(offering.id);

  const onLayout = useCallback(
    (event: LayoutChangeEvent) => {
      setHeight(event.nativeEvent.layout.height);
      const newImageSrc = getImageSrc({ height: event.nativeEvent.layout.height });
      Image.queryCache?.([newImageSrc]).then((uris) => {
        if (uris[newImageSrc]) {
          setImageSrc(newImageSrc);
        }
      });
    },
    [getImageSrc],
  );

  const ipoDate = useMemo(
    () =>
      // The IPO date does not have a timezone in the value, so when we parse it, it assumes UTC which means to get it to
      // be formatted the same way we input it, we need to add the timezone offset (converted to milliseconds).
      offering.ipoDate ? getTimezoneAgnosticDate(offering.ipoDate) : undefined,
    [offering.ipoDate],
  );

  const timePeriodSinceLastTransaction = useMemo(
    () =>
      offering.lastTransactionDate &&
      formatDistanceToNowStrict(parseISO(offering.lastTransactionDate), { addSuffix: true }),
    [offering.lastTransactionDate],
  );

  const content = useInvestCardContent(offering);
  const description = useGetProductTypeName(offering);

  const bg = useMemo(() => {
    switch (offeringTransactState) {
      case OfferingTransactState.CLOSED:
        return '$surface.positive.defaultAlt';
      case OfferingTransactState.IN_PROGRESS:
        return '$onSurface.primary.light';
      case OfferingTransactState.PRE:
        return '$onSurface.neutral.zebraAlt';
    }
  }, [offeringTransactState]);

  const adornmentContent = useMemo(() => {
    switch (offeringTransactState) {
      case OfferingTransactState.CLOSED:
        return (
          <>
            <CheckmarkCircleIcon color="$interactive.positive.rested" size="sm" />
            <UtilityText textTransform="uppercase" token="utility.label.xxsmall">
              <FormattedMessage id="common.funded" />
            </UtilityText>
          </>
        );
      case OfferingTransactState.IN_PROGRESS:
        if (
          investmentProductType &&
          (
            [
              InvestmentProductType.PRIVATE_CREDIT_FUND,
              InvestmentProductType.SINGLE_FAMILY_RESIDENTIAL_FUND,
            ] as InvestmentProductType[]
          ).includes(investmentProductType)
        ) {
          return (
            <>
              <PadlockIcon color="$onSurface.primary.decorative" size="sm" />
              <UtilityText textTransform="uppercase" token="utility.label.xxsmall">
                {isOfferingOwned ? (
                  <FormattedMessage id="invest.more-shares-available" />
                ) : (
                  <FormattedMessage id="common.available" />
                )}
              </UtilityText>
            </>
          );
        } else {
          return (
            <InvestCardProgress
              flex={1}
              offering={offering}
              UtilityTextProps={{ color: '$onSurface.neutral.default' }}
            />
          );
        }
      case OfferingTransactState.PRE:
        return <ClosedProgressBar />;
    }
  }, [investmentProductType, isOfferingOwned, offeringTransactState, offering]);

  const primaryTag = useMemo(() => {
    if (isOfferingOwned) {
      return (
        <ProductCard.Tag data-e2e="invest-primary-tag" variant="black">
          <Tag.Icon Icon={KeyIcon} />
          <Tag.Label>
            {numSharesOwned &&
            investmentProductType &&
            (
              [
                InvestmentProductType.PRIVATE_CREDIT_FUND,
                InvestmentProductType.SINGLE_FAMILY_RESIDENTIAL_FUND,
              ] as InvestmentProductType[]
            ).includes(investmentProductType) ? (
              <FormattedMessage
                id="invest.own-x-shares"
                values={{ count: <FormattedNumber value={numSharesOwned} /> }}
              />
            ) : (
              <FormattedMessage id="common.owned" />
            )}
          </Tag.Label>
        </ProductCard.Tag>
      );
    } else if (offering.securityType === SecurityType.REG_D) {
      return (
        <Tooltip>
          <Tooltip.Trigger asChild>
            <ProductCard.Tag data-e2e="invest-primary-tag" variant="black">
              <Tag.Icon Icon={PadlockIcon} />
              <Tag.Label>
                <FormattedMessage id="invest.advanced-investors" />
              </Tag.Label>
            </ProductCard.Tag>
          </Tooltip.Trigger>
          <Tooltip.Content tooltipTitle={<FormattedMessage id="invest.advanced-investors" />}>
            <Tooltip.Text>
              <FormattedMessage
                values={{ strong: (parts) => <Tooltip.Text fontWeight="600">{parts}</Tooltip.Text> }}
                id="invest.reg-d-confidential"
              />
            </Tooltip.Text>
          </Tooltip.Content>
        </Tooltip>
      );
    } else if (hasLimitedOfferingTag) {
      return (
        <ProductCard.Tag data-e2e="invest-primary-tag" variant="black">
          <Tag.Icon Icon={CoinsIcon} />
          <Tag.Label>
            <FormattedMessage
              id="common.max-investment"
              values={{
                maxInvestmentAmount: (
                  <FormattedNumber
                    style="currency"
                    currency="USD"
                    maximumFractionDigits={0}
                    value={(offering.maxSharesPerAccount ?? 0) * offering.sharePrice}
                  />
                ),
              }}
            />
          </Tag.Label>
        </ProductCard.Tag>
      );
    }
  }, [
    hasLimitedOfferingTag,
    investmentProductType,
    isOfferingOwned,
    numSharesOwned,
    offering.maxSharesPerAccount,
    offering.securityType,
    offering.sharePrice,
  ]);

  return (
    <ProductCard condensed dimmed={offeringDisplayState === OfferingDisplayState.PAUSED} bg={bg} {...rest}>
      <ProductCard.Tags>
        {primaryTag}
        {investCardTagContent && (
          <ProductCard.Tag variant={investCardTagContent.variant}>
            <Tag.Icon Icon={investCardTagContent.Icon} />
            <Tag.Label>
              <FormattedMessage
                id={investCardTagContent.message}
                values={{
                  ipoDate: ipoDate ? format(ipoDate, 'MMM do') : undefined,
                  timePeriodSinceLastTransaction,
                }}
              />
            </Tag.Label>
          </ProductCard.Tag>
        )}
      </ProductCard.Tags>
      <ProductCard.Image onLayout={onLayout} source={imageSrc} />
      <ProductCard.Title data-e2e="invest-card-title">{offering.name}</ProductCard.Title>
      {description.length > 0 && (
        <ProductCard.Description data-e2e="invest-card-type-label">
          {description.map((label, idx) => (
            <Fragment key={idx}>
              {idx >= 1 && <Divider.Vertical solid h={12} />}
              <ProductCard.Description.Text>{label}</ProductCard.Description.Text>
            </Fragment>
          ))}
        </ProductCard.Description>
      )}
      <ProductCard.Content data-e2e="invest-card-content" gap="$2">
        <Stack gap="$2" alignItems="center" row>
          {content.map((Component, idx) => (
            <Fragment key={idx}>
              {idx >= 1 && <Divider.Vertical solid h={12} />}
              <Component offering={offering} />
            </Fragment>
          ))}
        </Stack>
      </ProductCard.Content>
      <ProductCard.Adornment minHeight="$8" gap="$2" row justifyContent="center" alignItems="center">
        {adornmentContent}
      </ProductCard.Adornment>
    </ProductCard>
  );
};
